import translationKeys from './translationKeys';

const en = {
  [translationKeys.msg1]: 'Login',
  [translationKeys.msg2]: 'Agent Code',
  [translationKeys.msg3]: 'PIN Code',
  [translationKeys.msg4]: 'Enter the code',
  [translationKeys.msg5]: 'Enter your PIN',
  [translationKeys.msg6]: 'The agent code cannot be empty',
  [translationKeys.msg7]: 'PIN code cannot be empty',
  [translationKeys.msg8]: 'Show PIN code?',
  [translationKeys.msg9]: 'Top Matches',
  [translationKeys.msg10]: 'Today',
  [translationKeys.msg11]: 'Major Tournaments',
  [translationKeys.msg12]: 'Dashboard',
  [translationKeys.msg13]: 'Sports',
  [translationKeys.msg14]: 'Jackpot',
  [translationKeys.msg15]: 'Countries',
  [translationKeys.msg16]: 'Verify Ticket',
  [translationKeys.msg17]: 'History',
  [translationKeys.msg18]: 'Search',
  [translationKeys.msg19]: 'withdrawal',
  [translationKeys.msg20]: 'cash journal',
  [translationKeys.msg21]: 'Deposit',
  [translationKeys.msg22]: 'Loading...',
  [translationKeys.msg23]: 'Connection error, please contact support!',
  [translationKeys.msg24]: 'Pin Expired ! You must change it',
  [translationKeys.msg25]: 'Unknown Client',
  [translationKeys.msg26]: 'You are not allowed to login via this device',
  [translationKeys.msg27]: 'Incorrect PIN',
  [translationKeys.msg28]: 'agent Suspended',
  [translationKeys.msg29]: 'An error has occurred',
  [translationKeys.msg30]: 'PIN changed successfully',
  [translationKeys.msg31]: 'Your PIN must not contain consecutive digits',
  [translationKeys.msg32]: 'Two consecutive digits have been entered in your PIN',
  [translationKeys.msg33]: 'The bet is not registered on the server',
  [translationKeys.msg34]: 'Ticket already cancelled',
  [translationKeys.msg35]: 'Bet cancelled successfully',
  [translationKeys.msg36]: 'The cancellation of the bet has not been carried out!Please try again',
  [translationKeys.msg37]: 'Ticket paid successfully',
  [translationKeys.msg38]: 'Cancellation Dateline exceeded',
  [translationKeys.msg39]: 'You don\'t currently have rights to perform any action on this ticket',
  [translationKeys.msg40]: 'This amount ({{amount}}) is available',
  [translationKeys.msg41]: 'Please enter a valid combination.',
  [translationKeys.msg42]: 'Item already exists',
  [translationKeys.msg43]: 'Are you sure you want to pay this ticket?',
  [translationKeys.msg44]: 'Are you sure you want to cancel this ticket?',
  [translationKeys.msg45]: 'Confirm sale of {{amount}} coupon',
  [translationKeys.msg46]: 'success !',
  [translationKeys.msg47]: 'Withdrawal successful, please print the ticket.',
  [translationKeys.msg48]: 'Please wait...',
  [translationKeys.msg49]: 'validate',
  [translationKeys.msg50]: 'Pay',
  [translationKeys.msg51]: 'Cancel',
  [translationKeys.msg52]: 'Yes',
  [translationKeys.msg53]: 'Print',
  [translationKeys.msg54]: 'Amount',
  [translationKeys.msg55]: 'Submit',
  [translationKeys.msg56]: 'Client Code',
  [translationKeys.msg57]: 'Tickets sold',
  [translationKeys.msg58]: 'Tickets cancelled',
  [translationKeys.msg59]: 'Tickets paid',
  [translationKeys.msg60]: 'Direct recharge',
  [translationKeys.msg61]: 'Coupon sale',
  [translationKeys.msg62]: 'Start Date',
  [translationKeys.msg63]: 'Date',
  [translationKeys.msg64]: 'Type',
  [translationKeys.msg65]: 'Ref Code',
  [translationKeys.msg66]: 'TransID',
  [translationKeys.msg67]: 'No Data available.',
  [translationKeys.msg68]: 'End Date',
  [translationKeys.msg69]: 'History Details',
  [translationKeys.msg70]: 'VERIFY TICKET',
  [translationKeys.msg71]: 'Ticket Number',
  [translationKeys.msg72]: 'Client Code',
  [translationKeys.msg73]: 'OTP Code',
  [translationKeys.msg74]: 'Clear all',
  [translationKeys.msg75]: 'Stake',
  [translationKeys.msg76]: 'Potential Winnings',
  [translationKeys.msg77]: 'Total Stake',
  [translationKeys.msg78]: 'Accept odds change',
  [translationKeys.msg79]: 'Place bet',
  [translationKeys.msg80]: 'Logout',
  [translationKeys.msg81]: 'Change PIN',
  [translationKeys.msg82]: 'Combination',
  [translationKeys.msg83]: 'Coupon Code',
  [translationKeys.msg84]: 'Enter Client Code',
  [translationKeys.msg85]: 'Enter OTP Code',
  [translationKeys.msg86]: 'Ticket Sold',
  [translationKeys.msg87]: 'Ticket cancelled',
  [translationKeys.msg88]: 'Ticket Payment',
  [translationKeys.msg89]: 'Client code is incorrect',
  [translationKeys.msg90]: 'Amount is greater than the balance of the agent',
  [translationKeys.msg91]: 'Transaction failed',
  [translationKeys.msg92]: 'No data available',
  [translationKeys.msg93]: 'Result',
  [translationKeys.msg94]: 'Total of goals',
  [translationKeys.msg95]: 'Team goal',
  [translationKeys.msg96]: 'Goal scorer',
  [translationKeys.msg97]: 'Goal difference',
  [translationKeys.msg98]: 'Score',
  [translationKeys.msg99]: 'New PIN code cannot be blank',
  [translationKeys.msg100]: 'New PIN must be different',
  [translationKeys.msg101]: 'Change your PIN code',
  [translationKeys.msg102]: 'Old PIN',
  [translationKeys.msg103]: 'New PIN',
  [translationKeys.msg104]: 'Confirm new PIN',
  [translationKeys.msg105]: 'No Ticket found with this Ticket Number.',
  [translationKeys.msg106]: 'Ticket lost',
  [translationKeys.msg107]: 'Ticket already paid',
  [translationKeys.msg108]: 'Ticket expired !!',
  [translationKeys.msg109]: 'Unknown agent !!',
  [translationKeys.msg110]: 'A validation error occurred: The code is not available for this client',
  [translationKeys.msg111]: 'Withdrawal code has expired !',
  [translationKeys.msg112]: 'Withdrawal code already disbursed !',
  [translationKeys.msg113]: 'The generation of the pin code is not defined',
  [translationKeys.msg114]: 'Incomplete parameter',
  [translationKeys.msg115]: 'The amount of sales of the card is not defined on the server',
  [translationKeys.msg116]: 'Authorization failed',
  [translationKeys.msg117]: 'Unknown code',
  [translationKeys.msg118]: 'Unknown agent',
  [translationKeys.msg119]: 'Stock is empty',
  [translationKeys.msg120]: 'The amount is greater than the balance of the agent',
  [translationKeys.msg121]: 'Direct refill',
  [translationKeys.msg122]: 'Odds can not exceed',
  [translationKeys.msg123]: 'Number of events can not exceed',
  [translationKeys.msg124]: 'Maximum number of events for the bonus can not exceed',
  [translationKeys.msg125]: 'Stake can not exceed',
  [translationKeys.msg126]: 'Winnings can not exceed',
  [translationKeys.msg127]: 'Withdrawal code suspended !',
  [translationKeys.msg128]: 'This account is blocked !',
  [translationKeys.msg129]: 'This account is disabled !',
  [translationKeys.msg130]: 'Operation not allowed!',
  [translationKeys.msg131]: 'Device Not Assigned',
  [translationKeys.msg132]: 'Your session has expired. Please login again.',
  [translationKeys.msg133]: 'Vouchers',
  [translationKeys.msg134]: 'Enter Amount',
  [translationKeys.msg136]: 'Please confirm the new PIN code',
  [translationKeys.msg137]: 'The PIN codes do not match',
  [translationKeys.msg138]: 'Your airtime has been suspended. Contact the administrator',
  [translationKeys.msg139]: 'No outcomes for even',
  [translationKeys.msg140]: 'Can not find coupon!',
  [translationKeys.msg141]: 'Your account does not have access to this channel.',
  [translationKeys.msg142]: 'Coupon has expired!',
  [translationKeys.msg143]: 'Please enter a valid coupon code',
  [translationKeys.msg144]: 'Invalid stake amount',
  [translationKeys.msg145]: 'Stake after tax',
  [translationKeys.msg146]: 'Tax',
  [translationKeys.msg147]: 'Missing parameter!',
  [translationKeys.msg148]: 'Unknown user',
  [translationKeys.msg149]: 'Final winnings',
  [translationKeys.msg150]: 'You must wait for 1 min after the last deposit before making another',
  [translationKeys.msg151]: 'Search',
  [translationKeys.msg152]: 'Enter your search',
  [translationKeys.msg153]: 'BET SLIP',
  [translationKeys.msg154]: 'There are no games available at the moment. Please try again later.',
  [translationKeys.msg155]: 'Deposit failed',
  [translationKeys.msg156]: 'Stake can not be less than',
  [translationKeys.msg157]: 'Total odds',
  [translationKeys.msg158]: 'Pot. Winnings',
  [translationKeys.msg159]: 'An error has occurred with the printer',
  [translationKeys.msg160]: 'Integral bets not allowed',
  [translationKeys.msg161]: 'Stake should be a multiple of',
  [translationKeys.msg162]: 'Total Winnings',
  [translationKeys.msg163]: 'Page not Found',
  [translationKeys.msg164]: 'This page you are looking for might been removed, had its name changed or is temporarily unavailable.',
  [translationKeys.msg165]: 'Go to homepage',
  [translationKeys.msg166]: 'The number of events for this bet must be at least',
  [translationKeys.msg167]: 'The number of events for this bet must be at most',
  [translationKeys.msg168]: 'Enter the combination',
  [translationKeys.msg169]: 'Enter coupon code',
  [translationKeys.msg170]: 'Profile Menu',
  [translationKeys.msg171]: 'Change language',
  [translationKeys.msg172]: 'Log out',
  [translationKeys.msg173]: 'French',
  [translationKeys.msg174]: 'English',
  [translationKeys.msg175]: 'Register',
  [translationKeys.msg176]: 'Enter your current PIN',
  [translationKeys.msg177]: 'Enter the new PIN',
  [translationKeys.msg178]: 'Select a filter',
  [translationKeys.msg179]: 'You have exceeded the maximum number of daily transactions',
  [translationKeys.msg180]: 'This Product is not assigned you',
  [translationKeys.msg181]: 'Details',
  [translationKeys.msg182]: 'Account creation',
  [translationKeys.msg183]: 'Creating a customer account',
  [translationKeys.msg184]: 'Account activation',
  [translationKeys.msg185]: 'Phone number',
  [translationKeys.msg186]: 'Account validation',
  [translationKeys.msg187]: 'Invalid Phone number',
  [translationKeys.msg188]: 'Account created successfully! Please ask the customer to check their SMS to activate their account.',
  [translationKeys.msg189]: 'Enter the confirmation code sent to the number',
  [translationKeys.msg190]: 'You have not received the code?',
  [translationKeys.msg191]: 'Resend',
  [translationKeys.msg192]: 'Confirmation code',
  [translationKeys.msg193]: 'Operation completed successfully',
  [translationKeys.msg194]: 'This account is now active',
  [translationKeys.msg195]: 'dd/mm/yyyy',
  [translationKeys.msg196]: 'A ticket containing the account’s QR code has been generated, please print it to close the process.',
  [translationKeys.msg197]: 'PHONE NUMBER',
  [translationKeys.msg198]: 'ACCOUNT CODE',
  [translationKeys.msg199]: 'mm/dd/yyyy',
  [translationKeys.msg200]: 'Wrong account activation code',
  [translationKeys.msg201]: 'Bet for a client',
  [translationKeys.msg202]: 'Use the agent bonus',
  [translationKeys.msg203]: 'Customer code',
  [translationKeys.msg204]: 'Phone number',
  [translationKeys.msg205]: 'Enter the customer code',
  [translationKeys.msg206]: 'Invalid customer code',
  [translationKeys.msg207]: 'Enter the bonus code',
  [translationKeys.msg208]: 'Confirm',
  [translationKeys.msg209]: 'Unknown user',
  [translationKeys.msg210]: 'Select a bonus',
  [translationKeys.msg211]: 'Use the customer bonus',
  [translationKeys.msg212]: 'This agent has been suspended',
  [translationKeys.msg213]: 'The stake of the bet must be equal to the bonus amount',
  [translationKeys.msg214]: 'Unknown device type',
  [translationKeys.msg215]: 'There is a bet that has failed and must be cancelled.',
  [translationKeys.msg216]: 'There is a ticket that could not be printed.',
  [translationKeys.msg217]: 'Phone number is required',
  [translationKeys.msg218]: 'Enter your phone number',
  [translationKeys.msg219]: 'Wrong user credential',
  [translationKeys.msg220]: 'Printer is not connected',
  [translationKeys.msg221]: 'ACCOUNT NUMBER',
  [translationKeys.msg222]: 'RECHARGE  COUPON',
  [translationKeys.msg223]: 'RECHARGE CODE',
  [translationKeys.msg224]: 'Error occurred during printing',
  [translationKeys.msg225]: 'We are unable to identify you',
  [translationKeys.msg226]: 'Printing in progress...',
  [translationKeys.msg227]: 'Print the recharge ticket',
  [translationKeys.msg228]: 'Invalid code',
  [translationKeys.msg229]: 'An error occurred during the process',
  [translationKeys.msg230]: 'Confirm {{amount}} recharge',
  'resend-code': 'Resend in <1>{{seconds}}<1> sec',
  [translationKeys.msg231]: 'Please change your PIN.',
};

export default en;
